import Gumshoe from 'gumshoejs'

import { getClient, getPortfolio } from '../api'

const ab = document.querySelector('.menu-hamburger')
const header = document.querySelector('header')
const toTop = document.getElementById('toTop')
const mainNavAnchor = document.querySelectorAll('#mainNav a')
const clientPagnitaion = document.getElementById('clientPagnitaion')
const clientImage = document.getElementById('clientImage')
const loadingClient = document.getElementById('loadingClient')

const partnerPagination = document.getElementById('partnerPagination')
const partnerImage = document.getElementById('partnerImage')
const loadingPartner = document.getElementById('loadingPartner')

const outerPage = document.querySelector('.outer-page')
const toastText = document.getElementById('toastText')

const serviceSection = document.getElementById('service')
const serviceText = document.getElementById('serviceText')
const clientSection = document.getElementById('client')
const clientText = document.getElementById('clientText')
const partnerSection = document.getElementById('partner')
const partnerText = document.getElementById('partnerText')

function headerScroll() {
  const wScroll = window.scrollY
  if (wScroll > 40) {
    header.classList.add('header--scroll')
  } else {
    header.classList.remove('header--scroll')
  }
}
headerScroll()
window.addEventListener('scroll', headerScroll)

window.addEventListener('scroll', (e) => {
  const wScroll = window.scrollY
  const transformYService = (window.innerHeight - serviceSection.getBoundingClientRect().top - 350) * 0.25
  if (transformYService < 0.85) {
    serviceText.style.transform = `translateY(${transformYService}%)`
  }
  
  const transformYClient = (window.innerHeight - clientSection.getBoundingClientRect().top - 350) * 0.25
  if (transformYClient < 0.85) {
    clientText.style.transform = `translateY(${transformYClient}%)`
  }

  const transformYPartner = (window.innerHeight - partnerSection.getBoundingClientRect().top - 350) * 0.25
  if (transformYPartner < 0.85) {
    partnerText.style.transform = `translateY(${transformYPartner}%)`
  }
}, false)

ab.addEventListener('click', function () {
  this.classList.toggle('menu-hamburger--open')
  header.classList.toggle('header-menu--open')
  document.documentElement.classList.toggle('no-scroll')
})

mainNavAnchor.forEach((a) => {
  a.addEventListener('click', (e) => {
    ab.classList.remove('menu-hamburger--open')
    header.classList.remove('header-menu--open')
    document.documentElement.classList.remove('no-scroll')
  })
})

toTop.addEventListener('click', function () {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth'
  })
})

const spy = new Gumshoe('#mainNav a', {
  reflow: true,
  offset() {
    return header.getBoundingClientRect().height
  }
})

document.querySelectorAll('a[href^="#"]').forEach(anchor => {
  anchor.addEventListener('click', function (e) {
    e.preventDefault()
    document.querySelector(this.getAttribute('href')).scrollIntoView({
      behavior: 'smooth'
    })
  })
})

let paginationTotal
let paginationTotalPortfolio

getClient()
  .then((res) => {
    paginationTotal = Math.ceil(res.length / 8)
    let rowId = 0
    let parentChild

    for (let i = 0; i < paginationTotal; i++) {
      if (paginationTotal > 1) {
        const spanDom = document.createElement('span')
        spanDom.setAttribute('class', `pag-item ${i === 0 ? 'active' : ''}`)
        spanDom.setAttribute('data-client-row', i)
        spanDom.addEventListener('click', onClientPaginationClick)
        spanDom.textContent = i + 1
        clientPagnitaion.appendChild(spanDom)
      }

      const parentDom = document.createElement('div')
      parentDom.setAttribute('class', 'row')
      parentDom.setAttribute('id', `clientParentId${i}`)
      parentDom.setAttribute('style', `display: ${i === 0 ? 'flex': 'none'}`)

      clientImage.appendChild(parentDom)
    }

    for (let i = 0; i < res.length; i++) {
      if (i % 8 === 0) {
        parentChild = document.getElementById(`clientParentId${rowId}`)
        rowId++
      }
      const parentDom = document.createElement('div')
      parentDom.setAttribute('class', 'col-md-3 col-6 col-sm-6')
      parentDom.innerHTML = `
        <div class="img-client">
          <img src=${res[i].image} alt="">
        </div>
      `
      parentChild.appendChild(parentDom)
    }
  })
  .finally(() => {
    loadingClient.style.display = 'none'
    clientImage.style.minHeight = 'auto'
  })

function onClientPaginationClick () {
  const rowClientId = this.dataset.clientRow
  document.querySelectorAll('.pag-item').forEach((pag) => {
    pag.classList.remove('active')
  })
  this.classList.add('active')
  for (let i = 0; i < paginationTotal; i++) {
    if (parseInt(rowClientId) === i) {
      const domRow = document.getElementById(`clientParentId${i}`)
      domRow.style.display = 'flex'
    } else {
      const domRow = document.getElementById(`clientParentId${i}`)
      domRow.style.display = 'none'
    }
  }
}

getPortfolio()
  .then((res) => {
    paginationTotalPortfolio = Math.ceil(res.length / 8)
    let rowId = 0
    let parentChild

    for (let i = 0; i < paginationTotalPortfolio; i++) {
      if (paginationTotalPortfolio > 1) {
        const spanDom = document.createElement('span')
        spanDom.setAttribute('class', `pag-item-partner ${i === 0 ? 'active' : ''}`)
        spanDom.setAttribute('data-partner-row', i)
        spanDom.addEventListener('click', onPartnerPaginationClick)
        spanDom.textContent = i + 1
        partnerPagination.appendChild(spanDom)
      }

      const parentDom = document.createElement('div')
      parentDom.setAttribute('class', 'row')
      parentDom.setAttribute('id', `partnerParentId${i}`)
      parentDom.setAttribute('style', `display: ${i === 0 ? 'flex': 'none'}`)

      partnerImage.appendChild(parentDom)
    }

    for (let i = 0; i < res.length; i++) {
      if (i % 8 === 0) {
        parentChild = document.getElementById(`partnerParentId${rowId}`)
        rowId++
      }
      const parentDom = document.createElement('div')
      parentDom.setAttribute('class', 'col-md-3 col-6 col-sm-6')
      parentDom.innerHTML = `
        <div class="img-client">
          <img src=${res[i].image} alt="">
        </div>
      `
      parentChild.appendChild(parentDom)
    }
  })
  .finally(() => {
    loadingPartner.style.display = 'none'
    partnerImage.style.minHeight = 'auto'
  })

function onPartnerPaginationClick () {
  const rowPartnerId = this.dataset.partnerRow
  document.querySelectorAll('.pag-item-partner').forEach((pag) => {
    pag.classList.remove('active')
  })
  this.classList.add('active')
  for (let i = 0; i < paginationTotalPortfolio; i++) {
    if (parseInt(rowPartnerId) === i) {
      const domRow = document.getElementById(`partnerParentId${i}`)
      domRow.style.display = 'flex'
    } else {
      const domRow = document.getElementById(`partnerParentId${i}`)
      domRow.style.display = 'none'
    }
  }
}


// PORTFOLIO

// getPortfolio()
//   .then((res) => {
//     paginationTotalPortfolio = Math.ceil(res.length / 4)
//     let rowId = 0
//     let parentChild

//     for (let i = 0; i < paginationTotalPortfolio; i++) {
//       const spanDom = document.createElement('span')
//       spanDom.setAttribute('class', `pag-item-portfolio ${i === 0 ? 'active' : ''}`)
//       spanDom.setAttribute('data-portfolio-row', i)
//       spanDom.addEventListener('click', onPortfolioPaginationClick)
//       spanDom.textContent = i + 1
//       portfolioPagnitaion.appendChild(spanDom)

//       const parentDom = document.createElement('div')
//       parentDom.setAttribute('class', 'row')
//       parentDom.setAttribute('id', `portfolioParentId${i}`)
//       parentDom.setAttribute('style', `display: ${i === 0 ? 'flex': 'none'}`)

//       portfolioImage.appendChild(parentDom)
//     }

//     for (let i = 0; i < res.length; i++) {
//       if (i % 4 === 0) {
//         parentChild = document.getElementById(`portfolioParentId${rowId}`)
//         rowId++
//       }
//       const parentDom = document.createElement('div')
//       parentDom.setAttribute('class', 'col-6')
//       parentDom.innerHTML = `
//         <div class="portfolio-box" style="background-image: url(${res[i].image})">
//           <div class="portfolio-detail">
//             <p class="text-color--orange">${res[i].name}</p>
//             <p>${res[i].description}</p>
//           </div>
//         </div>
//       `
//       parentChild.appendChild(parentDom)
//     }
//   })
//   .finally(() => {
//     loadingPortfolio.style.display = 'none'
//   })

// function onPortfolioPaginationClick () {
//   const portfolioRow = this.dataset.portfolioRow
//   document.querySelectorAll('.pag-item-portfolio').forEach((pag) => {
//     pag.classList.remove('active')
//   })
//   this.classList.add('active')
//   for (let i = 0; i < paginationTotalPortfolio; i++) {
//     if (parseInt(portfolioRow) === i) {
//       const domRow = document.getElementById(`portfolioParentId${i}`)
//       domRow.style.display = 'flex'
//     } else {
//       const domRow = document.getElementById(`portfolioParentId${i}`)
//       domRow.style.display = 'none'
//     }
//   }
// }

function networkStatus (e) {
  outerPage.classList.add('show')
  
  if (navigator.onLine) {
    toastText.classList.remove('error')
    toastText.classList.add('success')
    toastText.textContent = `You're back online, enjoy 😊`
  } else {
    toastText.classList.remove('success')
    toastText.classList.add('error')
    toastText.textContent = `You're offline, but don't you worry, we have offline capability 👌`
  }
  
  setTimeout(() => {
    outerPage.classList.remove('show')
  }, 3000)
}

window.addEventListener('online', networkStatus)
window.addEventListener('offline', networkStatus)
