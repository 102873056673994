import axios from 'axios'

const api = axios.create({
  baseURL: process.env.BASE_URL_API
})

export const getClient = () => {
  return axios
          .get(`${process.env.BASE_URL_API}/client`)
          .then((res) => {
            return res.data
          })
          .catch((err) => {
            return Promise.reject(err)
          })
}

export const getPortfolio = () => {
  return api
          .get('/portfolio')
          .then((res) => {
            return res.data
          })
          .catch((err) => {
            return Promise.reject(err)
          })
}
